<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.sync_config") }}

      <v-spacer></v-spacer>
      <v-btn
        color="primary darken-1"
        small
        class="mr-2"
        outlined
        @click="addWarehouse"
        v-if="warehouses.length > 1 && [1, 5].includes(item.id_e_marketplace)"
      >
        {{ $t("labels.add_warehouse") }}
      </v-btn>
      <v-btn color="green darken-1" small class="mr-2" outlined @click="save">
        {{ $t("labels.confirm") }}
      </v-btn>
      <v-btn color="red darken-1" small outlined @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
    </v-card-title>

    <v-card-text v-for="(configItem, key) in items" :key="`c_${key}`">
      <div class="font-weight-medium error--text mb-3">
        {{ $t("labels.warehouse") }}
      </div>
      <div>
        <v-row>
          <v-col cols="3">
            <select-warehouse-model
              v-model="configItem.id_warehouse"
              :single-line="false"
            ></select-warehouse-model>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="configItem.e_marketplace_warehouse_code"
              class="c-input-small"
              :label="$t('labels.warehouse_code_e_market')"
              :placeholder="$t('labels.warehouse_code_e_market')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="configItem.e_marketplace_warehouse_name"
              class="c-input-small"
              :label="$t('labels.warehouse_name_e_market')"
              :placeholder="$t('labels.warehouse_name_e_market')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="configItem.e_marketplace_warehouse_id"
              class="c-input-small"
              :label="$t('labels.warehouse_id_e_market')"
              :placeholder="$t('labels.warehouse_id_e_market')"
              outlined
              dense
              hide-details
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div class="font-weight-medium error--text mb-3 mt-5">
        {{ $t("labels.sync_stock") }}
      </div>

      <div>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              class="c-input-small"
              v-model.number="configItem.auto_synchronize_stock"
              :items="activeDeactiveOptions"
              :label="$t('labels.sync_stock')"
              :placeholder="$t('labels.sync_stock')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
          <template v-if="configItem.auto_synchronize_stock">
            <v-col cols="3">
              <v-text-field
                v-model.number="configItem.synchronize_stock_percent"
                class="c-input-small"
                type="text"
                :label="$t('labels.quantity_percent')"
                :placeholder="$t('labels.quantity_percent_by_available')"
                dense
                outlined
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                class="c-input-small"
                v-model.number="configItem.synchronize_stock_type"
                :items="syncStockTypeOptions"
                :label="$t('labels.frequency_time')"
                :placeholder="$t('labels.frequency_by_time')"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <time-picker-dialog
                v-model="configItem.synchronize_stock_start_time"
                :label="$t('labels.start_time')"
                :placeholder="$t('labels.start_time')"
                :single-line="false"
              ></time-picker-dialog>
            </v-col>
          </template>
        </v-row>
      </div>

      <div class="font-weight-medium error--text mb-3 mt-5">
        {{ $t("labels.sync_order") }}
      </div>

      <div>
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              class="c-input-small"
              v-model.number="configItem.auto_synchronize_order"
              :items="activeDeactiveOptions"
              :label="$t('labels.sync_order')"
              :placeholder="$t('labels.sync_order')"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
          <template v-if="configItem.auto_synchronize_order">
            <v-col cols="6">
              <v-autocomplete
                class="c-input-small"
                v-model.number="configItem.synchronize_order_type"
                :items="syncOrderTypeOptions"
                :label="$t('labels.order_confirm_time_after_create')"
                :placeholder="$t('labels.frequency_by_time')"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                class="c-input-small"
                v-model.number="configItem.is_dropoff"
                :items="yesNoOptions"
                :label="$t('labels.pickup_dropoff')"
                :placeholder="$t('labels.pickup_dropoff')"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </v-col>
          </template>
        </v-row>
      </div>

      <template v-if="configItem.auto_synchronize_order">
        <div class="font-weight-medium error--text mb-3 mt-5 d-flex">
          {{ $t("labels.auto_synchronize_order_disable_time") }}
          <v-spacer></v-spacer>
          <v-btn
            x-small
            color="primary"
            @click="addDisableTime(configItem, key)"
          >
            {{ $t("labels.add") }}
          </v-btn>
        </div>
        <div>
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(
                    disableTime, index
                  ) in configItem.auto_synchronize_order_disable_time"
                  :key="`d_${key}_${index}`"
                >
                  <td>
                    <v-autocomplete
                      class="c-input-xs"
                      v-model.number="disableTime.is_confirm_created"
                      :items="yesNoOptions"
                      :label="
                        $t(
                          'labels.confirm_order_created_in_non_confirmation_period'
                        )
                      "
                      :placeholder="
                        $t(
                          'labels.confirm_order_created_in_non_confirmation_period'
                        )
                      "
                      dense
                      outlined
                      hide-details
                      @change="onDisableTimeChanged(configItem, key, index)"
                    ></v-autocomplete>
                  </td>
                  <td>
                    <time-picker-dialog
                      v-model="disableTime.from"
                      :label="$t('labels.from')"
                      :placeholder="$t('labels.from')"
                      :single-line="false"
                      @change="onDisableTimeChanged(configItem, key, index)"
                    ></time-picker-dialog>
                  </td>
                  <td>
                    <time-picker-dialog
                      v-model="disableTime.to"
                      :label="$t('labels.to')"
                      :placeholder="$t('labels.to')"
                      :single-line="false"
                      @change="onDisableTimeChanged(configItem, key, index)"
                    ></time-picker-dialog>
                  </td>
                  <td
                    style="min-width: 60px !important; width: 60px !important"
                    class="text-right"
                  >
                    <v-btn
                      x-small
                      color="error"
                      @click="removeDisableTime(configItem, key, index)"
                      style="width: 50px"
                    >
                      {{ $t("labels.delete") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </template>

      <v-divider class="mt-5" v-if="key < items.length - 1"></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import { ACTIVE_DEACTIVE_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "EMarketShopWarehouseConfig",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    activeDeactiveOptions: [...ACTIVE_DEACTIVE_OPTIONS],
    syncStockTypeOptions: [],
    syncOrderTypeOptions: [],
    yesNoOptions: [],
    warehouses: [],
  }),
  created() {
    const { warehouses } = window.me;
    this.warehouses = [...warehouses];
    const syncStockTypeOptions = [
      {
        text: this.$t("labels.realtime"),
        value: 0,
      },
      {
        text: this.$t("labels.hour_time", { hour: "01" }),
        value: 1,
      },
      {
        text: this.$t("labels.hour_time", { hour: "02" }),
        value: 2,
      },
      {
        text: this.$t("labels.hour_time", { hour: "03" }),
        value: 3,
      },
      {
        text: this.$t("labels.hour_time", { hour: "04" }),
        value: 4,
      },
      {
        text: this.$t("labels.hour_time", { hour: "06" }),
        value: 6,
      },
      {
        text: this.$t("labels.hour_time", { hour: "08" }),
        value: 8,
      },
      {
        text: this.$t("labels.hour_time", { hour: "12" }),
        value: 12,
      },
      {
        text: this.$t("labels.hour_time", { hour: "24" }),
        value: 24,
      },
    ];
    const syncOrderTypeOptions = [
      {
        text: this.$t("labels.realtime"),
        value: 0,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "01h" }),
        value: 1,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "02h" }),
        value: 2,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "03h" }),
        value: 3,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "04h" }),
        value: 4,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "05h" }),
        value: 5,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "06h" }),
        value: 6,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "07h" }),
        value: 7,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "08h" }),
        value: 8,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "09h" }),
        value: 9,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "10h" }),
        value: 10,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "11h" }),
        value: 11,
      },
      {
        text: this.$t("labels.time_after_order_create", { time: "12h" }),
        value: 12,
      },
    ];
    const yesNoOptions = [
      {
        text: this.$t("labels.yes"),
        value: 1,
      },
      {
        text: this.$t("labels.no"),
        value: 0,
      },
    ];
    this.syncStockTypeOptions = [...syncStockTypeOptions];
    this.syncOrderTypeOptions = [...syncOrderTypeOptions];
    this.yesNoOptions = [...yesNoOptions];
  },
  mounted() {
    this.getConfigs();
  },
  methods: {
    addDisableTime(item, index) {
      const time = {
        is_confirm_created: 1,
        from: "00:00",
        to: "07:00",
      };
      console.log(item);
      item.auto_synchronize_order_disable_time.unshift(time);
      this.items[index] = { ...item };
    },
    removeDisableTime(item, index, key) {
      item.auto_synchronize_order_disable_time = [
        ...item.auto_synchronize_order_disable_time,
      ].filter((v, i) => i != key);
      this.items[index] = { ...item };
    },
    onDisableTimeChanged(item, index) {
      item.auto_synchronize_order_disable_time = [
        ...item.auto_synchronize_order_disable_time,
      ];
      this.items[index] = { ...item };
    },
    close() {
      this.$emit("close", true);
    },
    addWarehouse() {
      this.items.push({
        id_warehouse: null,
        e_marketplace_warehouse_code: null,
        e_marketplace_warehouse_name: null,
        e_marketplace_warehouse_id: null,
        auto_synchronize_stock: 0,
        synchronize_stock_percent: 100,
        synchronize_stock_type: 0,
        synchronize_stock_start_time: "00:00:00",
        auto_synchronize_order: 0,
        synchronize_order_type: 0,
        is_dropoff: 0,
        auto_synchronize_order_disable_time: [],
      });
    },
    async getConfigs() {
      try {
        const { data } = await httpClient.post("/get-config-e-market-shop", {
          id: this.item.id,
        });
        this.items = [...data].map((item) => ({
          ...item,
          auto_synchronize_order_disable_time:
            item.auto_synchronize_order_disable_time
              ? JSON.parse(item.auto_synchronize_order_disable_time)
              : [],
        }));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async save() {
      const checkWarehouses = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (
          item.auto_synchronize_order_disable_time &&
          item.auto_synchronize_order_disable_time.length > 0
        ) {
          const checkErrorTime = [
            ...item.auto_synchronize_order_disable_time,
          ].filter((dt) => dt.from > dt.to);
          if (checkErrorTime && checkErrorTime.length > 0) {
            this.$vToastify.error(this.$t("messages.invalid_period"));
            return false;
          }
        }
        if (!item.id_warehouse) {
          this.$vToastify.error(this.$t("messages.wh_empty"));
          return false;
        }
        if (!checkWarehouses.includes(item.id_warehouse)) {
          checkWarehouses.push(item.id_warehouse);
        } else {
          this.$vToastify.error(this.$t("messages.2_config_1_wh"));
          return false;
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-config-e-market-shop", {
          id: this.item.id,
          items: this.items,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
